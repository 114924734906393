import React from "react";
import {
  Row,
  Col,
  Container,
  ListGroup,
  Image,
  Jumbotron,
  Button,
  Card
} from "react-bootstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import thnkdev_santa_monica from "../thnkdev_images/thnkdev_santa_monica.jpg";

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "thnkdev" }}>
    <SEO title="About" />
    <Card className="thnkdevSMBG">
      <Card.Img variant="top" src={thnkdev_santa_monica} />
      <Card.Body>
        <Card.Text>
          ThnkDev is a small independent development company started by a couple
          nerdy friends in high school in southern California. We love making
          software and we're always looking to get better. If you have any
          questions, feedback, or just want to reach out and say <i>"what's up"</i>, please
          drop us a line!
        </Card.Text>
        <div className="contactButton">
        <a href="mailto:thnkdev@gmail.com?Subject=Hi%20Thnkdev!" target="_top">
          <Button variant="primary" size="lg">
            Contact us!
          </Button>
          </a>
        </div>
      </Card.Body>
    </Card>
  </Layout>
);

export default IndexPage;
